<template>
  <div id="tools">
    <router-view />
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name:'tools',
  asyncData: function ({ store }) {
  },
  computed: mapState({
  }),
  data() {
    return {
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  beforeDestroy() {},
};
</script>
<style lang="less" scoped>
</style>